import React from 'react'
import { Facebook } from 'react-feather'

import './Footer.css'

export default () => (
  <div>
      <footer className="footer">
      <div className="container taCenter">
        <span>
          <a 
          href="https://www.facebook.com/McFarlandTransport" 
          target="_blank"
          >
            <Facebook 
            stroke="#3078be" 
            size="35"
            />
          </a>
        <br></br>
        <p className="copyright">&copy; Emmett Harper. Built with: <a href="https://www.gatsbyjs.com/"><u>Gatsby.js</u></a></p>
        </span>
      </div>
    </footer>
  </div>
)
